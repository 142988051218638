.edit-project {
  height: 100%; /* Postavite visinu na 100% visine prozora pregledača */

  .top-of-page {
    display: flex;
    flex-direction: row;

    svg {
      border-bottom: 20px;
      margin-right: 20px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
    nav {
      padding: 0 0 20px 0;
      display: flex;
      flex-direction: row;
      ul {
        display: flex;
        flex-direction: row;
        gap: 110px;

        li {
          a {
            color: #ffff;
            text-decoration: none;
          }

          a.active {
            color: #00b6aa;
          }
        }
      }
    }
  }
}
