.create-ticket {
  .add-ticket-and-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00b6aa;
      border: none;
      border-radius: 20px;
      padding: 10px 20px;
      color: white;

      &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
      }

      svg {
        color: white;
        margin-right: 5px;
      }
    }

    select {
      border-radius: 7px;
      border: 1px solid lightgray;
      background-color: white;
      padding: 10px;
    }
  }

  .tickets-table {
    border: 1px solid;
    border-radius: 20px;
    margin: 20px 0;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          border-bottom: 1px solid;

          th {
            width: 10%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: 5px;
            }
          }

          td {
            width: 10%;
            padding: 12px;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid;
          cursor: pointer;

          // &:hover {
          //   background-color: #4a4c72d6;
          // }

          // &:last-child:hover {
          //   border-radius: 0 0 20px 20px;
          // }

          &:last-child {
            border-bottom: none;
          }
          th {
            width: 10%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: auto;
            }
          }

          td {
            width: 10%;
            padding: 12px;

            &:last-child {
              width: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
