.add-level-popup {
  padding: 45px;
  background-color: #fff;
  color: black;
  border-radius: 10px;
  width: 50%;
  height: auto;
  left: 58%;
  top: 30%;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .add-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 30px;

    .dashed-border {
      border: 1px dashed lightgrey;
      border-radius: 0.25rem;
      padding: 20px;
      width: 100%;
      display: inline-block;
      svg {
        width: 24px;
        height: 24px;
        fill: #000;
        cursor: pointer;
      }
    }
  }

  .level-field {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: flex-start;

    input {
      padding: 5px;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-clip: padding-box;
      border: 1px solid #ced4da;
    }

    .custom-select {
      display: block;
      width: 30%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .custom-select:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
  .popup-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding-top: 20px;

    button {
      padding: 10px 20px;
      border: 1px solid lightgray;
      border-radius: 20px;
      background-color: white;
      color: black;
    }
  }
}
