.approve-the-registration {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .no-users {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 18px;
  }

  .user-container {
    display: flex;
    gap: 40px;
    align-items: center;
    padding: 20px;
    width: 100%;
    scroll-snap-type: mandatory;
    overflow-y: auto;
    max-height: calc(100vh - 100px);

    &:hover {
      background-color: #4a4c72d6;
      border-radius: 20px;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .user-actions {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-end;
      margin-left: auto;

      svg {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }
}
