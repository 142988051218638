.edit-user-info {
  display: flex;
  flex-direction: column;

  .top-of-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    svg {
      border-bottom: 20px;
      margin-right: 20px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  .bottom-of-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
    width: 60%;

    p {
      color: #00b6aa;
      display: flex;
      justify-content: center;
    }

    button {
      padding: 10px 40px;
      border-radius: 20px;
      margin-left: auto;
      border: none;
      background-color: white;
      color: #747474;
      margin-top: 20px;
    }

    .field {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      label {
        text-align: left;
        padding: 0.25rem 0;
        flex: 0 0 25%;
      }

      input {
        margin: 0.5rem 0;
        padding: 1rem;
        border: none;
        border-radius: 10px;
        width: 100%;
        flex: 1;
      }
    }
  }
}
