.users {
  .field {
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
    button {
      padding: 10px 30px;
      border-radius: 20px;
      border: 1px solid lightgray;
      background-color: #00b6aa;
      color: white;
    }
  }

  .users-table {
    border: 1px solid;
    border-radius: 20px;
    margin-bottom: 20px;

    table {
      width: 100%; /* Dodato */
      border-collapse: collapse; /* Dodato */

      thead {
        tr {
          border-bottom: 1px solid;
        }

        th {
          width: 10%;
          padding: 10px;

          &:first-child {
            width: 5%;
            padding: 10px;
          }

          &:last-child {
            padding: 0;
            width: 5px;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid;
          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }

          td {
            width: 10%;
            padding: 10px;

            &:first-child {
              width: 5%;
              padding: 10px;
            }

            &:last-child {
              padding: 10px 0;
              width: 5px;
              cursor: pointer;
            }

            svg {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
