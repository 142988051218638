.chose-role-popup {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  width: 50%;
  height: auto;
  left: 58%;
  top: 30%;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
  }

  h2 {
    color: black;
    padding: 10px;
  }

  h4 {
    color: black;
    padding: 20px;
  }
  .item {
    display: flex;
    gap: 20px;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    label {
      color: black;
    }
  }

  button {
    padding: 10px 50px;
    border: 1px solid lightgray;
    border-radius: 20px;
    background-color: white;
  }
}
