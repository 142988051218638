.view-project-tickets {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 70px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: auto;
  .add-ticket-and-filter {
    margin-bottom: 20px;
    display: flex;
    select {
      border-radius: 7px;
      border: 1px solid lightgray;
      background-color: white;
      padding: 10px;
    }
  }

  .tickets-table {
    border: 1px solid;
    border-radius: 20px;
    margin: 20px 0;
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          border-bottom: 1px solid;

          th {
            width: 10%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: 5px;
            }
          }

          td {
            width: 10%;
            padding: 12px;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid;
          cursor: pointer;

          // &:hover {
          //   background-color: #4a4c72d6;
          // }

          // &:last-child:hover {
          //   border-radius: 0 0 20px 20px;
          // }

          &:last-child {
            border-bottom: none;
          }
          th {
            width: 10%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: auto;
            }
          }

          td {
            width: 10%;
            padding: 12px;

            &:last-child {
              width: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
