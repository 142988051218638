.create-ticket-popup {
  padding: 10px;
  background-color: #fff;
  color: black;
  border-radius: 10px;
  width: 83%;
  height: 80%;
  left: 50%;
  top: 50%;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  .modal {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    // height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;

    .close-icon {
      margin-left: auto;
      margin-right: 10px;
      position: absolute;
      top: 10px;
      right: 0;
      cursor: pointer;
    }

    .modal-content {
      position: relative;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      background-color: none;
      border: none;
      flex-direction: row;
      justify-content: space-between;

      .download-icon {
        margin-left: auto;
        margin-right: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        width: 30px;
        height: 30px;
      }

      svg {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      img {
        width: 30%;
        height: auto;
        // max-width: 100%;
        // height: auto;
      }
    }
  }

  .form {
    width: 100%;
    gap: 40px;
    display: flex;
    flex-direction: row;

    .create-ticket-form {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 10px 20px;
      background-color: #ecf1f5;
      border-radius: 10px;

      .field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 10px;

        .checkbox-field {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: start;
          align-items: center;
        }

        .checkbox-field label {
          white-space: nowrap;
          display: flex;
          align-items: center;
        }

        .map-statuses {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 95%;

          div {
            width: 40%;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            box-sizing: border-box;

            input {
              width: 15px;
              height: 15px;
            }
          }
        }

        .add-image {
          width: 100%;

          label {
            display: flex;
            justify-content: flex-start;
          }
          button {
            margin-top: 10px;
            width: 95%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            color: black;
            border: none;
            border-radius: 20px;
            padding: 10px 20px;
            svg {
              color: black;
              margin-right: 10px;
            }
          }

          .new-files {
            padding: 20px;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
            border: 1px dashed lightgray;
            border-radius: 10px;
            margin-top: 20px;
            width: 95%;
            max-height: 100%;
            overflow-y: auto;
          }

          .new-files img,
          .new-files video {
            width: 100%;
            height: 130px;
            object-fit: contain;
            border-radius: 5px;
            transition: transform 0.2s ease-in-out;
            cursor: pointer;
          }

          .new-files img:hover,
          .new-files video:hover {
            transform: scale(1.1);
          }
        }

        select {
          width: 95%;
          border-radius: 10px;
          border: 1px solid lightgray;
          background-color: white;
          padding: 10px;
        }

        .react-select {
          width: 95%;
          margin-top: 8px;
        }

        .react-select__control {
          border-radius: 10px;
          border: 1px solid lightgray;
          background-color: white;
          padding: 10px;
        }

        .react-select__menu {
          border-radius: 10px;
        }

        .react-select__option {
          padding: 8px 10px;
        }

        input {
          width: 95%;
          border-radius: 10px;
          border: 1px solid lightgray;
          padding: 10px;
        }

        #myDatePicker {
          width: 100%;
        }
      }
    }

    .right-side {
      width: 50%;
      height: auto;
      .add-layers-and-attachments {
        .field {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 10px;
          padding: 10px 20px;
          border: 1px solid #ecf1f5;
          border-radius: 10px;
          margin-bottom: 20px;

          select {
            width: 95%;
            border-radius: 10px;
            border: 1px solid lightgray;
            background-color: white;
            padding: 10px;
          }

          button {
            margin-top: 10px;
            width: 95%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ecf1f5;
            color: black;
            border: none;
            border-radius: 20px;
            padding: 10px 20px;
            svg {
              color: black;
              margin-right: 10px;
            }
          }

          .new-files {
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px dashed lightgray;
            border-radius: 10px;
            margin-top: 20px;
            width: 95%;
          }
        }
      }

      .journal {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 10px;
        padding: 10px 20px;
        border: 1px solid #ecf1f5;
        border-radius: 10px;
        margin-bottom: 20px;

        .comment-field {
          display: flex;
          flex-direction: column;
          padding: 10px;
          width: 100%;

          .userName-and-time {
            display: flex;
            justify-content: space-between;
          }
          .comment {
            display: flex;
            justify-content: flex-start;
            border-left: 3px solid #386ea4;
            padding-left: 10px;
          }
        }

        .textarea-container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 100%;
          position: relative;
          padding-top: 40px;

          textarea {
            resize: none;
            height: 40px;
            padding: 10px;
            margin-bottom: 10px;
            width: 95%;
          }

          .send-icon {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: black;
            position: absolute;
            top: 65%;
            transform: translateY(-50%);
            right: 40px;
          }

          .send-icon:hover {
            color: #0056b3;
          }
        }
      }
    }
  }
  .buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 30px;

    .save {
      background-color: #ecf1f5;
    }

    button {
      padding: 10px 30px;
      border: 1px solid lightgray;
      border-radius: 20px;
      color: black;
      background-color: white;
    }
  }
}
