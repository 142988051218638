.add-users-popup {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  width: 50%;
  height: auto;
  left: 58%;
  top: 38%;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: auto;

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
  }

  .search {
    position: absolute;
    top: 20px;
    left: 10px;
    border: 1px solid lightgray;
  }

  .table {
    color: black;
    margin-top: 50px;
    border: 1px solid lightgrey;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          border-bottom: 1px solid lightgrey;

          th {
            width: 20%;
            padding: 12px;

            &:first-child {
              padding-left: 5px;
              width: auto;
            }

            &:nth-child(3),
            &:last-child {
              width: 20%;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid lightgrey;

          td {
            width: calc(
              (100% - 40%) / 5
            ); // Računanje ravnomernog raspoređivanja za preostale 5 kolona
            padding: 12px;

            &:first-child {
              padding-left: 5px;
              width: auto;
            }

            &:nth-child(3),
            &:last-child {
              width: 25%;
            }
          }
        }
      }
    }
  }

  .popup-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    button {
      padding: 10px 20px;
      border: 1px solid lightgray;
      border-radius: 20px;
      background-color: white;
    }
  }
}
