.brandschutzdoku-richtig-form {
  display: flex;
  flex-direction: column;

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;

    .form-name {
      display: flex;
      justify-content: flex-start;
      padding: 10px 20px;
    }

    .assign {
      display: flex;
      justify-content: flex-start;
      padding: 10px 20px;
      color: red;
    }
  }

  .table-form {
    border: 1px solid;
    padding: 20px;
    border-radius: 20px;
    table {
      tr {
        border-bottom: 1px solid lightgrey;

        th {
          width: 15%;
          padding: 12px;

          &:first-child {
            padding: 0;
            width: auto;
          }
        }

        td {
          width: 15%;
          padding: 12px;

          .select-field {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            svg {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }

          &:first-child {
            padding: 0;
            width: auto;
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      padding: 20px 0;

      button {
        padding: 10px 20px;
        border: 1px solid lightgray;
        border-radius: 20px;
        background-color: white;
      }
    }
  }
}
