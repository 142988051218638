.edit-list-popup {
  padding: 10px;
  background-color: #fff;
  color: black;
  border-radius: 10px;
  width: 55%;
  height: auto;
  max-height: 74%;
  left: 56%;
  top: 50%;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  .top-of-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid;
    width: 100%;

    svg {
      cursor: pointer;
    }
  }

  .middle-of-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    width: 100%;

    .list-name {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      input {
        width: 40%;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid;
      }
    }

    .add-item {
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: center;
      border: 1px solid black;
      padding: 10px;
      width: 50%;
      margin: 0 auto;
      justify-content: space-between;
      margin-bottom: 20px;
      border-radius: 7px;

      input {
        width: 90%;
        border: 1px solid gray;
        border-radius: 8px;
        padding: 5px;
      }

      svg {
        cursor: pointer;
      }
    }

    .list-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
      width: 50%;
      margin: 0 auto;
      overflow-y: auto;
      border-radius: 7px;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid;
        padding: 10px 0;
        cursor: pointer;

        &:hover {
          background-color: #00b6aa;
        }

        &:last-child {
          border-bottom: none;
        }

        div {
          margin-left: 10px;
        }

        svg {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
    }
  }
  button {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 30px;
    padding: 10px 30px;
    border-radius: 15px;
    background-color: lightgrey;
    border: none;
  }
}
