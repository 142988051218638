.edit-add-users {
  .new-user {
    border-radius: 10px;
    padding: 10px 40px;
    color: white;
    background-color: #00b6aa;
    border: 1px solid;
  }

  .add-users {
    border: 1px solid;
    border-radius: 20px;
    margin-bottom: 20px;

    table {
      width: 100%;

      thead {
        tr {
          border-bottom: 1px solid;

          th {
            padding: 12px;
            &:last-child {
              padding: 0;
              width: auto;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid;
          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }

          th,
          td {
            width: 17%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: auto;
              cursor: pointer;
            }

            svg {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
