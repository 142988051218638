.sidebar {
  // position: fixed;
  // left: 0;
  // top: 0;
  height: 100vh;
  .top-of-sidebar {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    cursor: pointer;
    position: relative;

    &:hover .hover-class {
      display: flex;

      div {
        width: 100%;
        &:hover {
          background-color: #00b6aa;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
  }

  .hover-class {
    display: none;
    width: 180px;
    height: 85px;
    top: 50px;
    left: 10px;
    background-color: white;
    color: black;
    border-radius: 20px;
    position: absolute;
    align-items: flex-end;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
  }

  .bottom-of-sidebar {
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px;
      border-bottom: 1px solid lightgray;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      cursor: pointer;

      .white-icon {
        color: white;
      }
    }
  }
}
