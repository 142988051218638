.forms {
  display: flex;
}

.left-side-of-forms {
  flex: 1;
  margin-right: 40px;

  h4 {
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
  }
  .available-forms {
    border: 1px solid;
    border-radius: 20px;

    .item {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid;

      &:last-child {
        border-bottom: none;
      }

      svg {
        margin-left: auto;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }
}

.right-side-of-forms {
  display: flex;
  flex-direction: row;
  flex: 3;

  .selected-forms {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 40px;

    h4 {
      display: flex;
      justify-content: flex-start;
      padding: 10px 20px;
    }

    .forms {
      border: 1px solid;
      border-radius: 20px;
      flex-direction: column;
      .selected-item {
        display: flex;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid;

        &:last-child {
          border-bottom: none;
        }

        p {
          margin: 0;
          padding-left: 8px;
        }

        svg {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }

        .open-item {
          margin-left: auto;
        }
      }
    }
  }

  .open-form {
    flex: 2;
  }
}
