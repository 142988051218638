.set-pin-popup {
  padding: 10px;
  background-color: #fff;
  color: black;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 52%;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .icons-container {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    z-index: 2;

    button {
      height: auto;
      width: auto;
      padding: 10px 30px;
      border: 1px solid lightgray;
      border-radius: 20px;
      color: black;
      background-color: white;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e6e6e6;
      }
    }
  }

  .top-of-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .left-side {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      position: fixed;
      top: 10px;
      left: 10px;
      border-right: none;
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.8);

      svg {
        cursor: pointer;
      }
    }
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      color: black;
      cursor: pointer;
    }
  }

  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in-out;

    .map {
      height: 400px;
      width: 100%;
    }

    .pin {
      width: 30px;
      height: 30px;
      background-color: none;
      position: absolute;
      cursor: grab;
      z-index: 1;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      transition: transform 0.5s ease-in-out;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: -o-crisp-edges;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }

    .dashed-border {
      border: 1px dashed lightgrey;
      border-radius: 0.25rem;
      padding: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}
