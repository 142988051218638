.blueprints-and-levels {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: 30px;

  button {
    padding: 10px 20px;
    border: 1px solid lightgray;
    border-radius: 20px;
    background-color: #00b6aa;
    color: white;
  }
  .select-level {
    display: flex;
    flex-direction: row;
    width: 100%;

    .open-plan {
      display: flex;
      flex: 2;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 30px;

      .dashed-border {
        border: 1px dashed lightgrey;
        border-radius: 0.25rem;
        padding: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          width: 24px;
          height: 24px;
          fill: white;
          cursor: pointer;
        }
      }
    }
    .layers {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: start;
      padding: 10px;

      .selected {
        color: #00b6aa;
      }

      .layer0 {
        display: block;
        gap: 10px;
        cursor: pointer;
        padding: 10px;

        svg {
          margin-right: 10px;
        }

        .edit {
          margin-left: 10px;
        }
      }
      .layer1 {
        display: block;
        padding-left: 10px;
        gap: 10px;
        cursor: pointer;
        padding: 10px 10px 10px 20px;
        svg {
          margin-right: 10px;
        }
        .edit {
          margin-left: 10px;
        }
      }
      .layer2 {
        display: block;
        padding: 10px 10px 10px 30px;
        gap: 10px;
        cursor: pointer;
        svg {
          margin-right: 10px;
        }
        .edit {
          margin-left: 10px;
        }
      }
      .layer3 {
        display: block;
        padding-left: 10px;
        gap: 10px;
        cursor: pointer;
        padding: 10px 10px 10px 40px;
        svg {
          margin-right: 10px;
        }
        .edit {
          margin-left: 10px;
        }
      }
      .layer4 {
        display: block;
        padding: 10px 10px 10px 50px;
        gap: 10px;
        cursor: pointer;
        svg {
          margin-right: 10px;
        }
        .edit {
          margin-left: 10px;
        }
      }
    }
  }
}
