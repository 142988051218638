.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    padding: 20px;
  }
  .table-form {
    border: 1px solid;
    border-radius: 20px;
    margin-bottom: 20px;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          border-bottom: 1px solid;

          th {
            width: 10%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: 5px;
            }
          }

          td {
            width: 10%;
            padding: 12px;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid;
          cursor: pointer;

          // &:hover {
          //   background-color: #4a4c72d6;
          // }

          // &:last-child:hover {
          //   border-radius: 0 0 20px 20px !important;
          // }

          &:last-child {
            border-bottom: none;
          }
          th {
            width: 10%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: auto;
            }
          }

          td {
            width: 10%;
            padding: 12px;

            &:last-child {
              width: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
