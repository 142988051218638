.create-project {
  height: 100%;

  nav {
    padding: 0 0 20px 0;
    ul {
      display: flex;
      flex-direction: row;
      gap: 110px;

      li {
        a {
          color: #ffff;
          text-decoration: none;
        }

        a.active {
          color: #00b6aa;
        }
      }
    }
  }
}
