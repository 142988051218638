.edit-list-item-name {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  color: black;
  border-radius: 10px;
  width: 60%;
  height: 45%;
  left: 50%;
  top: 60%;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  .list-item-name {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    padding: 20px 0;

    input {
      width: 75%;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 30px;
    button {
      margin-top: 20px;

      padding: 10px 30px;
      border-radius: 15px;
      background-color: lightgrey;
      border: none;
    }
  }
}
