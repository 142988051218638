.print-ticket {
  flex-direction: column;
  align-items: flex-start;
  display: block;
  page-break-before: always;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00b6aa;
    border: none;
    border-radius: 20px;
    padding: 10px 30px;
    color: white;
    svg {
      color: white;
      margin-right: 5px;
    }
  }

  .print {
    display: none;
  }
  @media print {
    .print {
      display: flex;
      flex-direction: column;

      .project-details {
        padding: 50px 0;
        width: 100%;
        height: 100%;

        .informations {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: left;
          border-top: 1px solid;
          border-bottom: 1px solid;
          padding: 20px 0;
        }
      }

      .ticket-details {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        .ticket-fields-details {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          border-top: 1px solid;
          border-bottom: 1px solid;
          padding: 20px 0;

          .ticket-fields {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-right: auto;
          }

          .thumbnail {
            width: 50%;
            img {
              width: 600px;
            }
          }
        }

        .ticket-images {
          padding: 20px 0;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          border-top: 1px solid;
          border-bottom: 1px solid;
          .image-row {
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;

            img {
              max-width: 50%;
              height: auto;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
