.lists {
  .lists-table {
    border: 1px solid;
    border-radius: 20px;
    margin: 20px 0;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          border-bottom: 1px solid;

          th {
            width: 10%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: 5px;
            }
          }

          td {
            width: 20%;
            padding: 12px;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid;
          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }
          th {
            width: 10%;
            padding: 12px;

            &:last-child {
              padding: 0;
              width: auto;
            }
          }

          td {
            width: 20%;
            padding: 12px;

            &:last-child {
              width: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  button {
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 15px;
    background-color: #00b6aa;
    color: white;
    border: none;
  }
}
