.invite-user {
  display: flex;
  flex-direction: column;

  .create-form {
    display: flex;
    flex-direction: column;
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 3rem 15.5rem;

    h4 {
      margin-bottom: 20px;
    }

    button {
      padding: 10px 40px;
      border-radius: 20px;
      margin-left: auto;
      border: none;
      background-color: white;
      color: #747474;
      margin-top: 20px;
    }

    .invite-user-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .field {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        width: 100%;

        label {
          text-align: left;
          padding: 0.25rem 0;
          flex: 0 0 10%;
        }

        input {
          margin: 0.5rem 0;
          padding: 1rem;
          border: none;
          border-radius: 10px;
          width: 100%;
          flex: 1;
        }
      }
    }
  }
}
