.edit-list {
  padding: 10px;
  background-color: #fff;
  color: black;
  border-radius: 10px;
  width: 50%;
  height: auto;
  left: 58%;
  top: 40%;
  position: fixed;

  box-shadow: rgba(164, 164, 238, 0.2) 0px 7px 29px 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 60%;
  overflow: auto;

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
  }

  .top-of-page {
    padding: 10px;
  }

  .middle-of-page {
    display: flex;
    flex-direction: column;

    .configure-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;
      padding: 10px;

      .checkbox-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-left: auto;
        margin-right: 20px;
      }
    }

    .choose-class {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;

      .name {
        margin-right: auto;
      }

      .buttons-and-search {
        button {
          margin-right: 20px;
          padding: 10px 20px;
          border: 1px solid lightgray;
          border-radius: 20px;
          background-color: white;
        }
        input {
          margin-right: 20px;
          padding: 10px 20px;
          border: 1px solid lightgray;
          background-color: white;
        }
      }
    }
  }

  .bottom-of-page {
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;

    .check-item {
      border-bottom: 1px solid lightgray;
      padding: 10px;
      gap: 20px;
      display: flex;
      align-items: flex-start;

      &:last-child {
        border-bottom: none;
      }

      input {
        margin-right: 20px;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 40px;

    button {
      padding: 10px 20px;
      border: 1px solid lightgray;
      border-radius: 20px;
      background-color: white;
    }
  }
}
