.register-page {
  @media screen and (min-width: 1280px) and (max-height: 800px) {
    img {
      height: 150px;
      width: 335px;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1559px) {
    // Styles specific to the resolution 1440x900
    img {
      height: 200px;
      width: 460px;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    // Styles specific to the resolution 1366x768
    img {
      width: 410px;
      height: 180px;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  @media screen and (min-width: 1920px) and (max-width: 2559px) {
    // Styles specific to the resolution 1920x1080 and 2560x1440
    img {
      height: 210px;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  @media screen and (min-width: 2560px) and (max-width: 3839px) {
    // Styles specific to the resolution 2560x1440 and 3840x2160
    img {
      width: 800px;
      height: 400px;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  @media screen and (min-width: 3840px) {
    // Styles specific to the resolution 3840x2160
    img {
      width: 900px;
      height: 450px;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
  .auth-form-container {
    display: flex;
    flex-direction: column;
    padding: 5rem; /* Adjust padding as needed */
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;

    h2 {
      font-size: 15px;
      padding: 10px 0;
    }

    .register-form {
      display: flex;
      flex-direction: column;

      p {
        color: rgb(184, 5, 5);
      }

      label {
        text-align: left;
        padding: 0.25rem 0;
      }

      input {
        padding: 1rem; /* Increase padding for better spacing */
        border: none;
        border-radius: 10px;
        width: 100%;
      }

      .create-password {
        position: relative;

        .password-toggle-btn {
          position: absolute;
          top: 16px; /* Adjust the top position as needed */
          right: 15px;
          color: black;
          cursor: pointer;
        }
      }

      .register-btn {
        border-radius: 10px;
        margin-top: 20px;
        width: 100%;
      }
    }

    button {
      border: none;
      background-color: white;
      padding: 20px; /* Increase padding for better spacing */
      border-radius: 10px;
      cursor: pointer;
      color: #6f87bb;
    }

    .link-btn {
      background: none;
      color: white;

      a {
        text-decoration: none;
        // color: #6f87bb;
        color: #00b6aa;
        font-weight: 700;
      }
    }
  }
}
