.home-page {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;

  .left-side {
    flex: 0 0 15%;
    border-right: 1px solid lightgray;
    height: auto;
  }

  .right-side {
    flex: 1;
    padding: 30px 50px;
  }
}
